import React, {useState, useEffect} from "react";
import axios from "axios";
import styles from '../../results.module.css';
import {useLocation} from 'react-router-dom';
import {
    AppBar,
    Box,
    Button,
    IconButton,
    InputBase, TextField,
    Toolbar,
    Typography,
    Container, Link
} from "@mui/material";
import {styled, alpha} from '@mui/material/styles';


import {Helmet} from "react-helmet";
import {ArrowForwardIos, SearchRounded} from "@mui/icons-material";


const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));


const SearchResults = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [results, setResults] = useState(null);

    const [showSearch, setShowSearch] = useState(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search);




    // Function to toggle the search input expansion
    // Toggle the expansion of the search input


    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch(event); // Assuming handleSearch is your search function
        }
    };

    const fetchResults = async (query) => {
        if (!query) {
            return;
        }
        try {
            const tid = new URLSearchParams(location.search).get('tid')
            const hostname = window.location.hostname
            let token
            if(hostname == "promo-search.com")
            {
                token = "QOcXs7u1"
            }
            else if(hostname == "searching-pro.com")
            {
                token = "TIJW6yU9"
            }
            else if(hostname == "qeeero.com")
            {
                    token = "2wpmcXF3"
            }


            const url = `https://api.${hostname}/feed?token=${token}&q=${query}&tid=${tid}`;
            const response = await axios.get(url);
            window.init(response.data.props);
            setResults(response.data.sponsored);

            window._tfa = window._tfa || [];
            window._tfa.push({notify: 'event', name: 'search', id: 1541269});
        } catch (error) {
            console.error("Error fetching info:", error);
        }
    };


    const getClickID = () => {
        const params = new URLSearchParams(location.search);
        return params.get('click_id');
    };

    const firePixel = (e) => {
        const clickID = getClickID();
        const pixelURL = `https://tracking.pixel.url?click_id=${clickID}`; // Replace with your tracking pixel base URL
        const img = new Image();
        img.src = pixelURL;
        window._tfa = window._tfa || [];
        window._tfa.push({notify: 'event', name: 'search_click', id: 1541269});

    };


    const handleSearch = async (event) => {
        event.preventDefault();
        
        // Update URL with search term
        const params = new URLSearchParams(location.search);
        params.set('q', searchTerm);
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.pushState({}, '', newUrl);
        
        fetchResults(searchTerm);
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://s.appreports.net/serenity.js?v=${new Date().getTime()}`;
        document.head.appendChild(script);
    }, []);

    // Added a new useEffect hook to listen for changes in the URL query params
    useEffect(() => {
        const query = params.get('q');
        if (query) {
            setSearchTerm(query);
            fetchResults(query);
        }
    }, [location.search]);


    if(!results && !showSearch && !params.get('q')) {
        return (
            <Container maxWidth="sm">
                <Box
                    sx={{
                        minHeight: '80vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <h1>{window.location.hostname}</h1>
                    <TextField
                        label="Search"
                        variant="outlined"
                        sx={{ width: '100%', mb: 2 }}
                        onChange={(event) => setSearchTerm(event.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                    <Button
                        variant="contained"
                        startIcon={<SearchRounded />}
                        onClick={handleSearch}
                        sx={{ width: '100%' }}
                    >
                        Search
                    </Button>
                </Box>
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        bgcolor: 'background.paper',
                        boxShadow: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            p: 1,
                            m: 1,
                            borderRadius: 1,
                            gap: 2,
                        }}
                    >
                        <div>© 2024 {window.location.hostname}</div>
                        <div>
                            <a
                                href="https://www.typ.com/privacy"

                                sx={{textDecoration: "none", color: "grey.900"}}
                                rel="noreferrer"
                                target={"_blank"}
                            >
                                Privacy
                            </a>

                        </div>
                        <div>
                            <a
                                href="https://www.typ.com/terms-and-conditions"

                                sx={{textDecoration: "none", color: "grey.900"}}
                                rel="noreferrer"
                                target={"_blank"}
                            >
                                T&C
                            </a>

                        </div>
                        <div>
                            <a
                                href="https://www.typ.com"

                                sx={{textDecoration: "none", color: "grey.900"}}
                                rel="noreferrer"
                                target={"_blank"}
                            >
                                Contact
                            </a>

                        </div>


                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            p: 1,
                            m: 1,
                            borderRadius: 1,
                            gap: 2,
                            textAlign: 'center'
                        }}
                    >
                        Traffic to this site originates from social media campaigns. Specific URL criteria must be met in order to display search ads.
                    </Box>
                </Box>
            </Container>
        )
    }



    return (
        <>
            <Helmet>
                <script type='text/javascript'>
                    {`
                    window._tfa = window._tfa || [];
                    window._tfa.push({notify: 'event', name: 'page_view', id: 1541269});
                    _tfa.push({notify: 'event', name: 'view_content', id: 1541269});
                    !function (t, f, a, x) {
                        if (!document.getElementById(x)) {
                            t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
                        }
                    }(document.createElement('script'),
                            document.getElementsByTagName('script')[0],
                            '//cdn.taboola.com/libtrc/unip/1541269/tfa.js',
                            'tb_tfa_script');
                `}
                </script>
            </Helmet>

            <Box sx={{flexGrow: 1}}>
                <AppBar position="static" sx={{ bgcolor: 'black', color: 'white' }}>
                    <Toolbar>

                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{flexGrow: 1}}
                        >
                            <Link href={"/"} sx={{color: "white", textDecoration: "none"}}> {window.location.hostname}</Link>
                        </Typography>
                        {!showSearch &&
                        <IconButton
                            sx={{display: {sm: 'none'}, color: "white"}}
                            onClick={() => setShowSearch(prev => !prev)}
                        >
                            <SearchRounded/>
                        </IconButton>}

                        <Search
                            sx={{
                                display: {xs: showSearch ? 'flex' : 'none', sm: 'flex'},
                            }}
                        >
                            <SearchIconWrapper>
                                <SearchRounded/>
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                focused
                                inputProps={{'aria-label': 'search'}}
                                value={searchTerm}
                                onChange={(event) => setSearchTerm(event.target.value)}
                                key="unique-key"
                                onKeyPress={handleKeyPress}
                            />
                        </Search>
                    </Toolbar>
                </AppBar>
            </Box>



            {results &&
                <Box
                    sx={{
                        textAlign: 'right',
                        width: '100%', // Ensure the Box takes the full width
                    }}
                >
                    <Typography variant="body2" sx={{mr: 2, mt: 2}} /* Adjust the variant for desired text size */>
                        Ads related to: <strong>{searchTerm}</strong>
                    </Typography>
                </Box>
            }

            <div>
                {results && results.map((result, index) => (
                    <div key={index} className={`ad ${styles["MuiCard-root"]}`}
                         data-yiid={result.beaconProps ? result.beaconProps.yiid || '' : ''}>
                        <div className={styles["MuiCardContent-root"]}>
                            <h2 className={styles["MuiTypography-root"] + " " + styles["MuiTypography-h5"]}>
                                <a href={result.clickUrl} 
                                   target="_blank" 
                                   onClick={firePixel}
                                   style={{display: "inline", textDecoration: "none", color: "black"}}
                                   dangerouslySetInnerHTML={{ __html: result.title }}>
                                </a>
                            </h2>

                            <div className="ad-body">
                                <div className={styles.adContainer}>
                                    <p className={`${styles["MuiTypography-root"]} ${styles["MuiTypography-body2"]} ${styles.siteHost}`}
                                       dangerouslySetInnerHTML={{ __html: result.siteHost }}>
                                    </p>
                                </div>
                                <p className={styles["MuiTypography-root"] + " " + styles["MuiTypography-body2"]}
                                   dangerouslySetInnerHTML={{ __html: result.description }}>
                                </p>
                                <div>
                                    {result.siteLinks && result.siteLinks.map((link, linkIndex) => (
                                        <span key={linkIndex}>
                                            <a href={link.url} 
                                               target="_blank"
                                               className={styles["MuiLink-root"]}
                                               dangerouslySetInnerHTML={{ __html: link.text }}>
                                            </a>
                                            {linkIndex < result.siteLinks.length - 1 && ' | '}
                                        </span>
                                    ))}
                                </div>
                                {/*<a href={result.clickUrl} target="_blank" onClick={firePixel}>*/}
                                {/*<Button*/}
                                {/*    variant="contained"*/}
                                {/*    fullWidth={true}*/}
                                {/*    sx={{*/}
                                {/*        mt: 2,*/}
                                {/*        backgroundColor: 'green', // use your desired color*/}
                                {/*        color: 'white',*/}
                                {/*        borderRadius: '20px', // make the corners rounded*/}
                                {/*        padding: '10px 20px', // adjust the padding as needed*/}
                                {/*        textTransform: 'none', // if you don't want uppercase letters*/}
                                {/*        fontSize: '1rem', // adjust the font size as needed*/}
                                {/*        '&:hover': {*/}
                                {/*            backgroundColor: 'darkgreen', // a darker red for hover state*/}
                                {/*        },*/}
                                {/*    }}*/}
                                {/*    endIcon={<ArrowForwardIos />}*/}
                                {/*>*/}
                                {/*    See It*/}
                                {/*</Button>*/}
                                {/*</a>*/}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bgcolor: 'background.paper',
                    boxShadow: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                        gap: 2,
                    }}
                >
                    <div>© 2024 {window.location.hostname}</div>
                    <div>
                        <a
                            href="https://www.typ.com/privacy"

                            sx={{textDecoration: "none", color: "grey.900"}}
                            rel="noreferrer"
                            target={"_blank"}
                        >
                            Privacy
                        </a>

                    </div>
                    <div>
                        <a
                            href="https://www.typ.com/terms-and-conditions"

                            sx={{textDecoration: "none", color: "grey.900"}}
                            rel="noreferrer"
                            target={"_blank"}
                        >
                            T&C
                        </a>

                    </div>
                    <div>
                        <a
                            href="https://www.typ.com"

                            sx={{textDecoration: "none", color: "grey.900"}}
                            rel="noreferrer"
                            target={"_blank"}
                        >
                            Contact
                        </a>

                    </div>


                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                        gap: 2,
                        textAlign: 'center'
                    }}
                >
                    Traffic to this site originates from social media campaigns. Specific URL criteria must be met in order to display search ads.
                </Box>


            </Box>
        </>
    );
};

export default SearchResults;
